.footer-wrap {
    background: #1890ff;
}

.left {
    background: #1890ff;
    padding-top: 1.5%;
}

.right {
    padding-top: 1%;
    margin-bottom: 1%;
}

.footerRow {
    position: absolute;
    top: 40%;
    left: 15%;
}

#wrap {
    background: rgb(24,144,255);
    background: radial-gradient(circle, rgba(24,144,255,0.8449754901960784) 0%, rgba(28,131,227,1) 100%);
}