
.top-proj {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 3%;
    background: rgb(24,144,255);
    background: radial-gradient(circle, rgba(24,144,255,0.8449754901960784) 0%, rgba(28,131,227,1) 100%);
    height: 500px;
}

.top-proj h2 {
    font-size: 4.5rem;
    color: aliceblue;
    text-align: center;
}

.pro-title {
    margin-top: 25%;
}

.repo {
    margin-top: 1%;
}

.repo1 {
    margin-top: 2%;
    margin-bottom: 10%;
}

.top-left {
    color: #003a8c;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
}

.top-right {
    height: 500px;
}
.top-right img {
    margin-top: 12.5%;
}

.front-top {
    display: flex;
    justify-content: center;
}

.back-top {
    margin-top: 3%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

.CS {
    background: white;
}

.front-boxes {
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.this {
    background: #1890ff;
    margin-top: 3%;
}

.port {
   margin: 0 auto;
   padding: 3%;
   color: white;
   
}

.back-boxes {
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

.cs-top {
    margin-top: 3%;
    display: flex;
    justify-content: center;
}

.cs-boxes {
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

#front-end-title {
    margin-top: 2%;
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

#back-end-title {
    margin-top: 2%;
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

#cs-title-id {
    margin-top: 2%;
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}
.follow:hover {
    opacity: 50%;
}