.modal-title {
    color: #003a8c;
    text-align: center;
    margin-top: 3%;
}

.resume-button {
    background: white;
}

.resume-button:hover {
    border: 1px solid white;
}