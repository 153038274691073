.top-about {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 3%;
    background: rgb(24,144,255);
    background: radial-gradient(circle, rgba(24,144,255,0.8449754901960784) 0%, rgba(28,131,227,1) 100%);
    height: 500px;
}

.top-about h2 {
    font-size: 4.5rem;
    color: aliceblue;
    text-align: center;
}

.top-about h3 {
    font-size: 2rem;
    color: aliceblue;
    text-align: center;
}

.name-title {
    justify-content: center;
    margin-top: 13%;
    margin-left: 10%;
}

.name {
    justify-content: center;
    
}

.title-developer {
    justify-content: center;
}

.track {
    padding-left: 1%;
    padding-right: 1%;
    text-align: center;
}

.titlediv {
    justify-content: center;
}

#life-title-id {
    margin-top: 2%;
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 4%;
}

.lambda-top {
    color: #003a8c;
    text-align: center;
    padding-right: 10%;
    padding-left: 10%;
    margin-bottom: 3%;
}

.lambda-bottom {
    color: #003a8c;
    text-align: center;
    padding-right: 10%;
    padding-left: 10%;
    margin: 0 auto;
}

#work-title-id {
    margin-top: 2%;
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 4%;
}

.work-title {
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 3%;
}

.two-col {
    margin-bottom: 3%;
    justify-content: center;
}

.left-work {
}

.right-work {
    
}

.location-title {
    justify-content: center;
    color: #003a8c;
    margin-bottom: 3%;
}

.card-about {
    margin: 0 auto;
    border-radius: 3px;
    margin-bottom: 3%;
    background: none;
}

.card-body {
    background-color: none;
    margin: 0 auto;
}

.card-subtitle {
    text-align: center;
    color: #003a8c;
}

.card-text {
    color: #003a8c;
}

.job-title {
    justify-content: center;
    color: #003a8c;
    margin-bottom: 3%;
}

.rel-title {
    justify-content: center;
    color: #003a8c;
    margin-bottom: 3%;
}

#lifebefore-title-id {
    text-decoration: none;
    color: #1890ff;
    border: 1px solid #003a8c;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 4%;
}

.before {
    justify-content: center;
    margin-bottom: 2%;
    color: #003a8c;
    text-align: center;
}

.titlebeforediv {
    justify-content: center;
}

.about-three-cards {
    justify-content: space-evenly;
    margin-bottom: 3%;
}

.card-link {
    
}