.top-skills {
    justify-content: space-evenly;
    margin-bottom: 3%;
    border: 1px solid black;
    background: rgb(24,144,255);
    background: radial-gradient(circle, rgba(24,144,255,0.8449754901960784) 0%, rgba(28,131,227,1) 100%);
    height: 500px;
}

.skills-title {
    margin-top: 15%;
}

.skills-title h2 {
    font-size: 4.5rem;
    color: aliceblue;
    text-align: center;
}

.skills-desc {
    padding-right: 5%;
    padding-left: 5%;
    text-align: center;
    color: #003a8c;
}

.skillbox-title h3 {
    font-size: 2rem;
    color: #1890ff;
    text-align: center;
    margin: 0 auto;
}

.skillbox {
    justify-content: center;
    margin-bottom: 3%;
}

.skills-cards {
    margin-top: 3%;
    max-width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: space-between;
}