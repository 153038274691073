.main-nav {
    max-width: 100%;
    background: rgb(24,144,255);
    background: linear-gradient(0deg, rgba(24,144,255,0.8785889355742297) 0%, rgba(28,131,227,0.9626225490196079) 100%);
}

#collasible-nav-dropdown {
    color: white;
    border: 1px solid white;
    border-radius: 5px;
}

#collasible-nav-dropdown:hover {
    background-color: white;
    color: #096dd9;
    transition: .5s;
}

#projects-nav-item {
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    width: 25%;
    text-align: center;
}

#projects-nav-item:hover {
    background-color: white;
    color: #096dd9;
    transition: .5s;
}

#about-nav-item {
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    width: 25%;
    text-align: center;
}

#about-nav-item:hover {
    background-color: white;
    color: #096dd9;
    transition: .5s;
}

.resume-button {
    color: #096dd9;
    text-align: center;
    padding-top: 1%;
}

.resume-button:hover {
    color: white;
    background: #096dd9;
}

.nav-left {
    width: 30%;
    justify-content: space-evenly;
}

.mr-auto {
    width: 40%;
    justify-content: space-evenly;
}

@media all and (max-width: 993px) {
    #collasible-nav-dropdown {
        border: 1px solid white;
        width: 100%;
        margin-bottom: 2%;
        text-align: center;
    }
    #projects-nav-item {
        border: 1px solid white;
        width: 100%;
        margin-bottom: 4%;
    }
    #about-nav-item {
        border: 1px solid white;
        width: 100%;
        margin-bottom: 4%;
    }
    #collasible-nav-dropdown:hover {
        
    }
    #projects-nav-item:hover {
        
    }
    #about-nav-item:hover {

    }
}