.topdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100vh;
    background: #3f81b3 url('../images/rencen3.jpg') no-repeat center;
	  background-size: cover;
    overflow: hidden;
}

.title {
  justify-content: center;
  position: absolute;
}

@media all and (max-width: 770px) {
  .title {
    top: 54.12%;
  }
}

@media all and (max-width: 420px) {
  .title {
    top: 49%;
  }
}

@media all and (max-width: 400px) {
  .title {
    top: 46.5%;
  }
}

@media all and (max-width: 375px) {
  .title {
    top: 51%;
  }
}

@media all and (max-width: 325px) {
  .title {
    top: 44%;
  }
}

h1 {
  position: relative;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: calc(10px + 10vw);
  font-weight: 700;
  color: #40a9ff;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  perspective: 500px;
}

h1::before,
h1::after {
  content: attr(carl-label);
  position: absolute;
  top: 133px;
  left: 0;
  transform: translate(-50%, -50%);
  text-shadow: 0.01em 0.01em 0.01em rgba(0, 0, 0, 0.3);
}

h1::before {
  animation: floatAbove 3.5s ease-in-out infinite;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%);
}

h1::after {
  opacity: 0.65;
  color: #0050b3;
  filter: blur(0.02em);
  transform: translate(-50%, -50%) rotateX(21deg);
  animation: floatBelow 3.5s ease-in-out infinite;
  -webkit-clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}

@keyframes floatAbove {
  50% {
      transform: translate(-50%, -60%);
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%);
              clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 60%);
  }
}

@keyframes floatBelow {
  50% {
      transform: translate(-50%, -60%) rotateX(10deg);
      -webkit-clip-path: polygon(0% 60%, 100% 60%, 100% 100%, 0% 100%);
              clip-path: polygon(0% 60%, 100% 60%, 100% 100%, 0% 100%);
  }
}